import Service from "../Service";
import Vue from "vue";
const resource = "gpt/";

export default {
    searchgpt(texto, requestID) {
        return Service.post(resource + "chat", {}, {
            params: { texto: texto, requestID: requestID },
        });
    },

};