<template>
<div>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

    <v-col cols="12">
        <h1>INTEGRACIÓN GPT</h1>
    </v-col>
    <v-col cols="12">
        <s-textarea label="Consulta" v-model="texto"></s-textarea>
        <v-btn color="success" class="capitalize" x-small elevation="0" @click="search()">Buscar</v-btn>
    </v-col>

    <v-col cols="12">
        <h1>RESPUESTA</h1>
    </v-col>
    <v-col cols="12">
         <v-textarea auto-grow ref="OrdObservation"  label="Resultado" v-model="response" />
    </v-col>

</div>
</template>

<script>
import _sService from '@/services/General/GptService';
export default {
    components: {

    },
    name: 'WorkspaceJsonChatGTP',

    data() {
        return {
            texto: null,
            response: null,
            processing: false,
            messageProcessing: "",
        };
    },

    mounted() {

    },

    methods: {
        search() {
            console.log("this.texto", this.texto);

            this.messageProcessing = ""
            this.processing = true
            _sService.searchgpt(this.texto, this.$fun.getUserID()).then(resp => {

                    if (resp.status == 200) {
                        this.messageProcessing = ""
                        this.processing = false;
                        var data = JSON.parse(resp.data)
                        this.response = data.choices[0].message.content
                        //console.log("respuesaaaaaaaaaaaaaa", data);
                    }

                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                });
        }
    },
};
</script>

<style lang="scss" scoped>

</style>
